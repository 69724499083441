.divContener{
    height: 100%;
    width: 100%;
    text-align: center;
}
.divCenter{
    height: 500px;
    /*width: 35%;*/
    margin: 0px auto;
}
.divText{
    width: 100%;
    height:10%;
    justify-content: center;
    display: flex;
    align-items: flex-end;   
}
h1{
    font-size:2rem;
    color: #5A69BF;  
    font-family: Arial, Helvetica, sans-serif;  
}
._progress{
    width: 4rem;
    height:4rem;
}
.divImg{
    width: 100%;
    height: 30%;
    justify-content: center;
    display: flex;
    align-items: center;
}
.imgLogo{
    width: 100%;
    height: 120pt;     
    padding-right: 0%;
    object-fit: contain;
}