    *{
        margin: 0;
        padding: 0;
    }
    .error{
        border-color: red;
    }
    .border{
        border: 0px solid black;
    }
    .primary.dropdown-toggle {
        border-color: #0062cc !important;
    }
    .btn-primary:hover {
        border-color: #0069d9 !important;
    }
    .row {
        margin-right: 0px !important; 
        margin-left: 0px !important;
    }
    @media (max-width: 575.98px) 
    {    
        .input-feedback{
            color: rgb(235, 54, 54);
            margin-top: 0px;
            font-size: 14px;
        }    
        .message{
            font-size: 14px;
            text-align: center;
        }
        .doc, .box{
            display: none;
            visibility: hidden;
            width: 0px !important; 
            height: 0px !important;
        }
        .Logo{
            width: 100%;
            height: 240px;
            justify-content: center;
            display: flex;
            align-items: center;
        }
        .imgLogo{
            width: 90%;
            height: 120pt;     
            padding-right: 0%;
            object-fit: contain;
        }
        .form-content{
            padding: 9%;
            margin-right: 0;
            margin-left: 0;
        }
        .Input{
            background-color:#EFF0F1 !important;
            border: none;
            color: #999999;
            font-family: Roboto;
            font-size: 18px;
            width: 90%;
            height: auto;
        }
        .form-input{
            width: 100%;
            justify-content: center;
            display: flex;
            height: auto;
        }
        .boton{
            width: 90%;
            height: 35px;
            font-size: 20px;
            color: #ffffff;
            background-color: #008DCC;
            border-radius: 22px;
            padding: 0%;
            border: #008DCC;
        }
        .form-width{
            width: 100%;
            height: 100px;
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }
    @media (min-width: 576px) and (max-width: 767.98px) 
    {        
        .input-feedback{
            color: rgb(235, 54, 54);
            margin-top: 0px;
            font-size: 22px;
        }
        .message{
            font-size: 22px;
            text-align: center;
        }       
        .doc, .box{
            display: none;
            visibility: hidden;
            width: 0px !important; 
            height: 0px !important;
        }
        .Logo{
            width: 100%;
            height:200px;
            padding-right: 0%;
            justify-content: center;
            display: flex;
            align-items: flex-end;
        }
        .imgLogo{
            width: 90%;
            height: 170px;
            padding-right: 0%;
            object-fit: contain;
            margin-bottom: 3%;
        }
        .form-content{
            padding: 10%;
            margin-right: 0;
            margin-left: 0;
        }
        .Input{
            background-color:#EFF0F1 !important;
            border: none;
            color: #999999;
            font-family: Roboto;
            font-size: 24px;
            width: 70%;
            height: 53px;
        }
        .form-input{
            width: 100%;
            justify-content: center;
            display: flex;
            height: auto;
        }
        .boton{
            width: 70%;
            height: 53px;
            font-size: 24px;
            color: #ffffff;
            background-color: #008DCC;
            border-radius: 22px;
            padding: 0%;
            border: #008DCC;
        }
        .form-width{
            width: 100%;
            height: 100px;
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 991.98px)
    and (orientation : landscape) 
    { 
        .col-md-4 {
            -ms-flex: 0 0 100%;  
            flex: 0 0 100%;
            max-width: 100%;                 
        }
        .doc, .box{
            display: none;
            visibility: hidden;
            width: 0px !important; 
            height: 0px !important;
            justify-content: center;
        }
        .Logo{
            width: 100%;
            height:45vh;
            padding-right: 0%;
            justify-content: center;
            display: flex;
            align-items: flex-end;        
        }
        .imgLogo{
            width: 80%;
            height: 120pt;      
            padding-right: 0%;
            object-fit: contain;
            margin-bottom: 2%;
        }
        .form-content{
            margin-top: 2%;          
        }
        .Input{
            background-color:#EFF0F1 !important;
            border: none;
            color: #999999;
            font-family: Roboto;
            width: 50%;
            height: 50px;
            font-size: 20px;
        }
        .boton{
            width: 50%;
            height: 50px;
            font-size: 20px;
            color: #ffffff;
            background-color: #008DCC;
            border-radius: 32px;
            padding: 0%;
            border: #008DCC;
        }
        .form-width{
            width: 100%;
            height: 90px;
            justify-content: center;
            display: flex;
            align-items: center;
        }
        .form-input{
            width: 100%;
            justify-content: center;
            display: flex;
            height: auto;
        }
        .input-feedback{
            color: rgb(235, 54, 54);
            margin-top: 0px;
            font-size: 16px;
        }
        .message{
            font-size: 16px;
            text-align: center;
        }
    }
    @media only screen  and (min-width : 768px) and (max-width : 1199px) 
    and (orientation : portrait)
    {        
        .input-feedback{
            color: rgb(235, 54, 54);
            margin-top: 0px;
            font-size: 26px;
        }
        .message{
            font-size: 26px;
            text-align: center;
        }
        .col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;        
        }
        .doc, .box{
            display: none;
            visibility: hidden;
            width: 0px !important; 
            height: 0px !important;
        }
        .Logo{
            width: 100%;
            height:35vh;
            padding-right: 0%;
            justify-content: center;
            display: flex;
            align-items: flex-end;
        }
        .imgLogo{
            width: 80%;
            height: 23vh;      
            padding-right: 0%;
            object-fit: contain;
            margin-bottom: 6%;
        }
        .form-content{
            padding: 8%;
        }
        .Input{
            background-color:#EFF0F1 !important;
            border: none;
            color: #999999;
            font-family: Roboto;
            font-size: 28px;
            height: 75px;
            width: 80%;
        }
        .form-input{
            width: 100%;
            justify-content: center;
            display: flex;
            height: auto;
        }
        .boton{
            width: 80%;
            height: 75px;
            font-size: 28px;
            color: #ffffff;
            background-color: #008DCC;
            border-radius: 32px;
            padding: 0%;
            border: #008DCC;
        }
        .form-width{
            width: 100%;
            height: 150px;
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }   
    @media only screen  and (min-width : 992px) and (max-width : 1199px) 
    and (orientation : landscape)
    {         
        .col-lg-6 {
            -ms-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
            max-width: 58.333333%;
        } 
        .input-feedback{
            color: rgb(235, 54, 54);
            margin-top: 0px;
            font-size: 18px;
        }
        .message{
            font-size: 18px;
            text-align: center;
        }
        .box {
            width: 100%;
            display: inline-block;
            margin: 0;
            padding: 0;
            vertical-align: middle;
        }
        .full-height {
            width: auto; 
            height: 100vh;
            position: relative;
            max-width: 45em;
            left: -2.6%; 
        }
        .App{
            text-align: left !important;
        }
        .Logo{
            width: 100%;
            height:41vh;
            justify-content: center;
            display: flex;
            align-items: flex-end;
            padding-right: 0%;
            
        }
        .imgLogo{
            width: 90%;
            height: 110pt;     
            padding-right: 0%;
            object-fit: contain;
            margin-bottom: 5%;
        }
        .Input{
            background-color:#EFF0F1 !important;
            border: none;
            color: #999999;
            font-family:  Roboto;
            width: 70%;
            height: auto;
            font-size: 20px;
        }
        .form-input{
            width: 100%;
            justify-content: center;
            display: flex;
            height: auto;
        }
        .boton{
            width: 70%;
            height: 40px;
            font-size: 20px;
            color: #ffffff;
            background-color: #008DCC;
            border-radius: 22px;
            padding: 0%;
            border: #008DCC;
        }
        .form-width{
            width: 100%;
            height: 60px;
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }
    @media (min-width: 1200px)  
    { 
        .input-feedback{
            color: rgb(235, 54, 54);
            margin-top: 0px;
            font-size: 14px;
            padding: 0%;
        }
        .message{
            font-size: 16px;
            text-align: center;
        }
        .box {
            display: inline-block;
            margin: 0;
            padding: 0;
            vertical-align: middle;
        }
        .full-height {
            width: auto;
            height: 100vh;
            position: relative;
            max-width: 57em;
            left: -2.6%; 
        }
        .App{
            text-align: left !important;
        }
        .Logo{
            width: 100%;
            height:43vh;
            padding-right: 0%;
            justify-content: center;
            display: flex;
            align-items: flex-end;
        }
        .imgLogo{
            width: 75%;
            height: 120pt;     
            padding-right: 0%;
            object-fit: contain;
            margin-bottom: 2%;
        }       
        .form-content{
            padding-right: 0;
            padding-left: 0;
            margin-right: 0;
            margin-left: 0;
        }
        .Input{
            background-color:#EFF0F1 !important;
            border: none;
            color: #999999;
            font-family:  Roboto;
            font-size: 18px;
            width: 45%;            
        }
        .form-input{
            width: 100%;
            justify-content: center;
            display: flex;
            height: auto;
        }
        .boton{
            width:45%;
            height: 33px;
            font-size: 18px;
            color: #ffffff;
            background-color: #008DCC;
            border-radius: 22px;
            padding: 0%;
            border: #008DCC;
        }
        .form-width{
            width: 100%;
            height: 60px;
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }
    @media only screen  and (width : 1280px) and (height : 1024px)   
    {         
        .full-height {
            max-width: 66em;
            left: -12.6%;
        }
        .imgLogo {
            margin-left: 10%;
        }
        .Input{
            margin-left: 10%;
        }
        .boton{
            margin-left: 10%;
        }
        .message {
            font-size: 17px;
            margin-left: 10%;
        }
        .input-feedback {
            font-size: 16px;
        }
    }
    @media only screen  and (width : 1024px) and (height : 768px)   
    {         
        .full-height {
            max-width: 66em;
            left: -8.6%;
        }
    }
    @media (min-width: 1400px) 
    { 
        .input-feedback{
            color: rgb(235, 54, 54);
            margin-top: 0px;
            font-size: 18px;
            padding: 0%;
        }
        .message{
            font-size: 18px;
            text-align: center;
        }
        .box {
            display: inline-block;
            margin: 0;
            padding: 0;
            vertical-align: middle;
        }    
        .full-height {
            height: 100vh;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-size: cover;
            left: -2.6%; 
        }
        .App{
            text-align: left !important;
        }
        .Logo{
            width: 100%;
            height:43vh;
            padding-right: 0%;
            justify-content: center;
            display: flex;
            align-items: flex-end;
        }
        .imgLogo{
            width: 100%;
            height: 140pt;     
            padding-right: 0%;
            object-fit: contain;
            margin-bottom: 4%;
        }
        .Input{
            background-color:#EFF0F1 !important;
            border: none;
            color: #999999;
            font-family:  Roboto;
            font-size: 22px;
            width: 50%;
            height: auto;
            height: 46px;
        }
        .form-input{
            width: 100%;
            justify-content: center;
            display: flex;
            height: auto;
        }
        .boton{
            width: 50%;
            height: 46px;
            font-size: 20px;
            color: #ffffff;
            background-color: #008DCC;
            border-radius: 24px;
            padding: 0%;
            border: #008DCC;
        }
        .form-width{
            width: 100%;
            height: 70px;
            justify-content: center;
            display: flex;
            align-items: center;
        }
        .input-feedback{
            color: rgb(235, 54, 54);
            margin-top: 0px;
            font-size: 20px;
            margin-bottom: 20px;
        }
    }
    @media (min-width: 1800px) 
    { 
        .col-xl-8 {
            -ms-flex: 0 0 63%;
            flex: 0 0 63%;
            max-width: 63%;
        }
        .input-feedback{
            color: rgb(235, 54, 54);
            margin-top: 0px;
            font-size: 22px;
            padding: 0%;
        }
        .message{
            font-size: 22px;
            text-align: center;
        }
        .box {
            display: inline-block;
            margin: 0;
            padding: 0;
            vertical-align: middle;
        }    
        .full-height {
            height: 100vh;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-size: cover;
            left: -2.6%; 
        }
        .App{
            text-align: left !important;
        }
        .Logo{
            width: 100%;
            height:43vh;
            padding-right: 0%;
            justify-content: center;
            display: flex;
            align-items: flex-end;
        }
        .imgLogo{
            width: 100%;
            height: 165pt;     
            padding-right: 0%;
            object-fit: contain;
            margin-bottom: 4%;
        }
        .Input{
            background-color:#EFF0F1 !important;
            border: none;
            color: #999999;
            font-family:  Roboto;
            font-size: 24px;
            width: 45%;
            height: 45px;
            max-height: 45px;
        }
        .form-input{
            width: 100%;
            justify-content: center;
            display: flex;
            height: auto;
        }
        .boton{
            width: 45%;
            height: 45px;
            font-size: 24px;
            color: #ffffff;
            background-color: #008DCC;
            border-radius: 22px;
            padding: 0%;
            border: #008DCC;
        }
        .form-width{
            width: 100%;
            height: 80px;
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }